import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxjqmHHRzyQEteBone-nwAXg3zjYACPPM",
  authDomain: "polo-ladu.firebaseapp.com",
  projectId: "polo-ladu",
  storageBucket: "polo-ladu.appspot.com",
  messagingSenderId: "1022730920306",
  appId: "1:1022730920306:web:15add4946534ad7087fdeb",
  measurementId: "G-PWN7TZEDTT"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase authentication
const auth = getAuth(firebaseApp);

export { auth };